import SEO from "../components/seo"

import React, {useState, useEffect} from 'react';

import DrinkCard from '../components/DrinkCard'
import {DrinkData} from '../data/DrinkData.js'
import MenuAppBar from '../components/AppBar'
import Filter from '../components/Filter'
import Sort from '../components/Sort'
import Grow from '@material-ui/core/Grow';
import Loader from '../components/Loader'

// import CircularProgress from '@material-ui/core/CircularProgress';

// import Fab from '@material-ui/core/Fab';
// import AddIcon from '@material-ui/icons/Add';
import {navigate} from "gatsby"
import Grid from '@material-ui/core/Grid';

// import getFirebase from '../utils/firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
// import firebase from 'firebase/app';
import "firebase/auth";

import CompanyPage from '../components/CompanyPage'

// import {TwitterTimelineEmbed} from 'react-twitter-embed';

// import CircularProgress from '@material-ui/core/CircularProgress';

import {makeStyles} from '@material-ui/core/styles';

import Layout from "../components/layout";

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

import Typography from '@material-ui/core/Typography';

import {useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import firebase from 'firebase/app';

// import Button from '@material-ui/core/Button';

// import Chip from '@material-ui/core/Chip';
import 'typeface-work-sans';

import Slide from '@material-ui/core/Slide';

// import LinkedInIcon from '@material-ui/icons/LinkedIn';
// import FacebookIcon from '@material-ui/icons/Facebook';
// import TwitterIcon from '@material-ui/icons/Twitter';

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/',
  // We will display Google and Facebook as auth providers.
  signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID]
};

const useStyles = makeStyles(theme => ({
  title: {
    fontFamily: 'Work Sans',
    fontWeight: '800',
    fontSize: '60px',
    backgroundColor: '',
    lineHeight: '1',
    textAlign: 'center',
    paddingBottom: '0px',
    [
      theme
        .breakpoints
        .down('md')
    ]: {
      fontSize: '48px'
    },
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      fontSize: '45px'
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      fontSize: '40px'
    }
  },
  cardContainer: {
    padding: '20px',
    backgroundColor: 'red',
    [
      theme
        .breakpoints
        .up('sm')
    ]: {
      // height: '550px'
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      marginBottom: '20px'
    }
  },
  dialog: {
    padding: '40px',
    // textAlign: 'center',
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      padding: '20px'
    }
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props}/>;
});

// const Transition2 = React.forwardRef(function Transition(props, ref) {
//   return <Grow ref={ref} {...props}/>;
// });

function LoginDialog(props) {
  const [styledAuth, setStyledAuth] = useState(false)

  useEffect(() => {
    setStyledAuth(
      firebase
      ? <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
      : undefined)
  }, []);

  const classes = useStyles();
  const {onClose, open} = props;

  const handleClose = () => {
    onClose();
  };

  return (<Dialog onClose={handleClose} aria-labelledby="login-dialog-title" open={open} TransitionComponent={Transition}>
    <div className={classes.dialog}>
      <DialogTitle>Continue to StartHub</DialogTitle>
      {styledAuth}
    </div>
  </Dialog>);
}

function CompanyDialog(props) {

  // const classes = useStyles();
  const {onClose, open} = props;

  const handleClose = () => {
    onClose();
  };

  return (<Dialog fullScreen="fullScreen" onClose={handleClose} aria-labelledby="login-dialog-title" open={open} TransitionComponent={Transition}>
    <div style={{
        backgroundColor: 'white',
        height: '100vh',
        width: '100vw'
      }}>
      <CompanyPage drinkData={props.openCompany} openCompanyDialog={props.openCompanyDialog} closeCompanyDialog={props.closeCompanyDialog} setOpenCompany={props.setOpenCompany}/>
    </div>
  </Dialog>);
}

// function CompanyDialog(props) {
//   const theme = useTheme();
//
//   const classes = useStyles();
//   const {onClose, open} = props;
//
//   const handleClose = () => {
//     onClose();
//   };
//
//   let LinkedIn = <LinkedInIcon onClick={() => openWebsite(props.drinkData.linkedin_url)}/>
//   let Facebook = <FacebookIcon onClick={() => openWebsite(props.drinkData.facebook_url)}/>
//   let Twitter = <TwitterIcon onClick={() => openWebsite(props.drinkData.twitter_url)}/>
//
//   let socialIcons = <Grid>{props.drinkData.linkedin_url ? LinkedIn : null}{props.drinkData.facebook_url ? Facebook : null}{props.drinkData.twitter_url ? Twitter : null}</Grid>
//
//   const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));
//
//   function openWebsite(website) {
//     if (typeof window !== `undefined`) {
//       window.open(website)
//     }
//   }
//
//   let twitterName
//
//   if (props.drinkData.twitter_url) {
//     if (props.drinkData.twitter_url.includes('https://www')) {
//       twitterName = props
//         .drinkData
//         .twitter_url
//         .slice(24)
//     } else if (props.drinkData.twitter_url.includes('https://')) {
//       twitterName = props
//         .drinkData
//         .twitter_url
//         .slice(20)
//     } else {
//       twitterName = props
//         .drinkData
//         .twitter_url
//         .slice(19)
//     };
//      console.log(twitterName)
//   }
//
//   return (<Dialog onClose={handleClose} aria-labelledby="login-dialog-title" open={open} TransitionComponent={Transition} style={{}}>
//     <div className={classes.dialog}>
//       <Grid container="container" direction="column" justify="center" alignItems="center">
//         <div style={{
//             width: '50px',
//             height: '50px',
//              display: 'flex'
//           }}>
//           <img id='card-img' width='50px' height='50px' alt='Logo' src={props.drinkData.profile_image_url} style={{
//               borderRadius: '5px',
//               objectFit: 'contain'
//             }}/></div>
//         <Typography className={classes.headingTypography} align='center' utterBottom="utterBottom" variant="h5" component="h2">
//           {props.drinkData.name}
//         </Typography>
//         <Typography className={classes.bodyTypography} align='center' variant="body2" color="textSecondary" component="p">
//           {props.drinkData.short_description}
//         </Typography>
//         <Grid container="container" direction={isXsDown
//             ? 'column'
//             : 'row'} justify="center" className={classes.chipContainer}>
//           <Chip className={classes.chip} label={'Rank #' + props.drinkData.id} variant="outlined" color='primary' clickable='true'/>
//           <Chip className={classes.chip} label={'$' + props.drinkData.funding + 'M Funding'} variant="outlined" color='primary' clickable='true'/>
//           <Chip className={classes.chip} label={props.drinkData.city_name} variant="outlined" color='primary' clickable='true'/>
//         </Grid>
//         <Typography className={classes.detail} align='center' variant="body2" color="textSecondary" component="p">
//           {'Founded in ' + props.drinkData.founded}
//         </Typography>
//         <Button onClick={() => openWebsite(props.drinkData.homepage_url)} className={classes.button} size="medium" variant='contained' color="secondary">
//           Visit Website
//         </Button>
//         {socialIcons}
//       </Grid>
//       <TwitterTimelineEmbed sourceType="profile" screenName={twitterName} options={{
//           height: 400
//         }}/>
//     </div>
//   </Dialog>);
// }

export default function Catalog() {

  // const firebase = getFirebase();
  // const [currentCompany, setCurrentCompany] = useState(false);

  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [companyDialogIsOpen, setCompanyDialogIsOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [activeSort, setActiveSort] = React.useState('rank');
  const [activeFilter, setActiveFilter] = React.useState('all');
  const [openCompany, setOpenCompany] = React.useState(null);

  const openSigninDialog = () => {
    setDialogIsOpen(true);
  };
  const closeDialog = value => {
    setDialogIsOpen(false);
  }
  const closeCompanyDialog = value => {
    setCompanyDialogIsOpen(false);
  }

  // const [companyDialogIsOpen, setCompanyDialogIsOpen] = useState(false);

  // const openCompanyDialog = (companyID) => {
  //   setCurrentCompany(DrinkData.find(company => {
  //     return company.id === companyID
  //   }))
  //   setCompanyDialogIsOpen(true);
  // };
  // const closeCompanyDialog = value => {
  //   setCompanyDialogIsOpen(false);
  // }

  const classes = useStyles();

  // State
  // const [activeUser, setActiveUser] = useState(undefined);
  // const [isLoaded, setIsLoaded] = useState(false);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    // setTimeout(function(){setIsLoaded(true)}, 300)
    setIsLoaded(true)
  }, []);

  // useEffect(() => {
  //   if (!firebase)
  //     return;
  //   firebase
  //     .auth()
  //     .onAuthStateChanged((user) => {
  //       if (user) {
  //          alert('User is signed in')
  //          setIsSignedIn(!!user);
  //         const db = firebase.firestore();
  //         let docRef = db
  //           .collection("users")
  //           .doc(user.uid);
  //         docRef
  //           .get()
  //           .then(function(doc) {
  //             if (doc.exists) {
  //               setIsLoaded(true);
  //             } else {
  //                doc.data() will be undefined in this case
  //               navigate('/signedin')
  //             }
  //           })
  //           .catch(function(error) {
  //             console.log("Error getting document:", error);
  //           });
  //       } else {
  //         navigate('/')
  //       }
  //     });
  // });

  const handleRandomClick = () => {
    navigate("/random",);
  }

  function sortArrayByPopular(array) {
    return array.sort(function(a, b) {
      var idA = a.id
      var idB = b.id
      if (idA < idB) {
        return -1;
      }
      if (idA > idB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
  };

  function sortArrayByAge(array) {
    return array.sort(function(a, b) {
      var idA = a.founded
      var idB = b.founded
      if (idA > idB) {
        return -1;
      }
      if (a < b) {
        return 1;
      }

      // names must be equal
      return 0;
    });
  };

  function sortArrayByFunding(array) {
    return array.sort(function(a, b) {
      var idA = a.funding
      var idB = b.funding
      if (idA > idB) {
        return -1;
      }
      if (a < b) {
        return 1;
      }

      // names must be equal
      return 0;
    });
  };

  let cardArray;

  if (activeFilter === 'all') {
    cardArray = DrinkData
  } else if (activeFilter === 'Other') {
    let otherCities = [
      'Kitchener',
      'Ottawa',
      'Quebec City',
      'Burnaby',
      'Mississauga',
      "St. John's",
      'Victoria',
      'Waterloo'
    ]
    cardArray = DrinkData.filter(company => otherCities.includes(company.city_name))
  } else {
    cardArray = DrinkData.filter(company => company.city_name === activeFilter)
  }

  // else if (activeFilter === 'none') {
  //   cardArray = []
  // } else if (activeFilter === 'toronto') {
  //   cardArray = DrinkData.filter(company => company.city_name === 'Toronto')
  // } else if (activeFilter === 'montreal') {
  //   cardArray = DrinkData.filter(company => company.city_name === 'Montréal')
  // } else if (activeFilter === 'vancouver') {
  //   cardArray = DrinkData.filter(company => company.city_name === 'Vancouver')
  // } else if (activeFilter === 'stjohns') {
  //   cardArray = DrinkData.filter(company => company.city_name === "St. John's")
  // } else if (activeFilter === 'ottawa') {
  //   cardArray = DrinkData.filter(company => company.city_name === 'Ottawa')
  // }

  let sortedCardArray;

  if (activeSort === 'rank') {
    sortedCardArray = sortArrayByPopular(cardArray)
  } else if (activeSort === 'none') {
    sortedCardArray = []
  } else if (activeSort === 'funding') {
    sortedCardArray = sortArrayByFunding(cardArray)
  } else if (activeSort === 'age') {
    sortedCardArray = sortArrayByAge(cardArray)
  }
  // <Grow in="in">
  //   <Grid container="container" justify="center" alignItems="center" className={classes.cardContainer}>
  //     <DrinkCard drinkData={drink}/>
  //   </Grid>
  // </Grow>

function openCompanyDialog() {
  setCompanyDialogIsOpen(true);
  // while (!openCompany) {
  //   break
  // }
  // window.history.pushState({}, "", '/' + openCompany.permalink)
}

  const cardCatalog = sortedCardArray.map((drink) => <Grow in="in">
    <Grid item="item"><DrinkCard drinkData={drink} setOpenCompany={setOpenCompany} openCompanyDialog={openCompanyDialog}/></Grid>
  </Grow>)

  const finalPage = <Layout>
    <div className={classes.root}><SEO title="Home"/>
      <MenuAppBar handleRandomClick={handleRandomClick} openSigninDialog={openSigninDialog}/> {
        isLoaded
          ? (<div style={{
              padding: '65px 2vw 4vh'
            }}>
            <Grid container="container" direction={matches
                ? "column"
                : "row"} justify={matches
                ? "center"
                : "space-between"} alignItems="center" style={{
                padding: '30px 20px',
                backgroundColor: ''
              }}>
              <Grid item="item" style={{
                  backgroundColor: ''
                }}>
                <Typography className={classes.title}>The 2020 Narwhal List</Typography>
              </Grid>
              <Grid item="item">
                <Grid container="container" direction="row" justify="center" alignItems="center" style={{
                    paddingBottom: matches
                      ? "15px"
                      : "0px",
                    paddingTop: matches
                      ? "15px"
                      : "0px"
                  }}>
                  <Sort setActiveSort={setActiveSort}/>
                  <Filter setActiveFilter={setActiveFilter}/>
                </Grid>
              </Grid>
            </Grid>
            <Grid container="container" direction='row' justify="center" alignItems="center">
              {cardCatalog}
            </Grid>
          </div>)
          : <Loader/>
      }
      <LoginDialog open={dialogIsOpen} onClose={closeDialog}/>
      <CompanyDialog open={companyDialogIsOpen} onClose={closeCompanyDialog} closeCompanyDialog={closeCompanyDialog} openCompany={openCompany} setOpenCompany={setOpenCompany}/>
    </div>
  </Layout>

  // Render
  return (finalPage)
}

// <CompanyDialog drinkData={currentCompany} open={companyDialogIsOpen} onClose={closeCompanyDialog}/>
